import { Injectable } from "@angular/core";
import { Gtag } from 'angular-gtag';
import { environment } from '../../environments/environment';

@Injectable()
export class GoogleAnalyticsService {
  gtag: any;

  constructor(gtag: Gtag) {
    this.gtag = gtag;
  }

  addToCart(product, quantity) {
    this.gtag.event('addToCart', {
      ecommerce: {
        currencyCode: 'USD',
        add: {
          products: [this.toGtagProduct(product, quantity)]
        }
      }
    });
  }

  removeFromCart(product, quantity) {
    this.gtag.event('removeFromCart', {
      ecommerce: {
        currencyCode: 'USD',
        remove: {
          products: [this.toGtagProduct(product, quantity)]
        }
      }
    });
  }

  sendEvent(name, data) {
    this.gtag.event(name, data);
  }

  sendOptimizeEvent(name) {
    this.gtag.event('optimize.' + name);
  }

  private toGtagProduct(product, quantity) {
    return {
      id: product.id,
      name: product.title,
      category: '',
      brand: environment.brand,
      variant: product.activeOption.productFirstOption + ' ' + product.activeOption.productSecondOption,
      quantity: quantity,
      price: product.activeOption.price
    };
  }
}

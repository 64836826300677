import { Component, OnInit, Output, EventEmitter, Input, Inject, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Event } from '@angular/router';
import { DOCUMENT } from "@angular/common";
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  @Output() public upgradeClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onCloseMenu: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onOpenMenu: EventEmitter<any> = new EventEmitter<any>();
  @Input() products;
  isOpen: boolean = false;
  isBannerOpened: boolean = false;
  environment = environment;
  constructor(@Inject(DOCUMENT) private document: any,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.isBannerOpened = !localStorage.getItem('COOKIES_POLICY');
      }
    });
  }

  ngOnInit() {}

  gotoPage(page) {
    this.router.navigate([page]);
  }

  openMenu() {
    this.onOpenMenu.emit('openMobileMenu')
  }

  closeMenu() {
    this.onCloseMenu.emit('closeMobileMenu')
  }

  openCart(){
    this.isOpen = !this.isOpen;
    if (this.isOpen)
      this.document.body.classList.add('noscroll');
    else
      this.document.body.classList.remove('noscroll');
  }

}

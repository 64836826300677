import { Component, OnInit, Input, HostListener, ViewChild, ElementRef } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

import { environment } from "../../../environments/environment";
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  environment = environment;
  checkoutButtonText: string = environment.default_checkout_text;

  @Input() pageOptions: any;
  @Input() product: any;
  @Input() showDatesSelector: any;

  @ViewChild('datesSelector') datesSelector: ElementRef;

  isSelectBoxActive: boolean = false;
  isFooterSelectBoxActive: boolean = false;
  isSoldOut: boolean = false;
  showHintTooltip: boolean = false;
  confirmItemAdded = false;

  // showDatesSelector: boolean = false;
  stickyShowMoreOptions: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 1200) {
      this.showDatesSelector = true;
    } else {
      this.checkSize();
    }
  }

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private cartService: CartService,
  ) { }

  ngOnInit() {
  }

  createCart(order) {
    if (!this.pageOptions.isValidOrder || !this.checkIfVariantAvailable(this.pageOptions.tickets.active)) {
      this.showHintTooltip = true;
      setTimeout(() => this.showHintTooltip = false, 4000);
      return;
    }
    let newCartProduct = Object.assign({}, order.product);
    newCartProduct.activeOption = this.getActiveOption(); //fallback to support old cart functionality TODO: rewrite it
    this.cartService.cart = { type: 'update', product: newCartProduct, quantity: this.parseInt(order.quantity) };
    this.track(`${environment.brand}: Purchase Package`, 'Add to cart', newCartProduct.title);
    this.googleAnalyticsService.addToCart(newCartProduct, this.parseInt(order.quantity));
    this.showSuccessAddInfo();
  }

  selectQny(qny) {
    this.pageOptions.quantity = qny;
    this.isSelectBoxActive = false;
    this.isFooterSelectBoxActive = false;
    this.countPrice();
  }

  setOptionAsActive(d) {
    this.pageOptions.date = d;
    this.pageOptions.isDateOptionSelected = true;
    this.track(`${environment.brand}: Select Date`, 'Select date on package details page', d.formatted.soDate);
    this.isSoldOut = this.checkIfSoldOut();
    if (!this.checkIfVariantAvailable(this.pageOptions.tickets.active))
      this.pageOptions.tickets = { selected: false, active: false };
    this.countPrice();
  }

  countPrice() {
    if (this.pageOptions.isDateOptionSelected && this.pageOptions.tickets.selected) {
      this.pageOptions.price = this.getActiveOption().price * this.pageOptions.quantity;
      this.pageOptions.isValidOrder = true;
    } else
      this.pageOptions.isValidOrder = false;
  }

  checkIfSoldOut() {
    if (!this.pageOptions.date.options)
      return true;
    return this.pageOptions.date.options.every(x => !x.available);
  }

  checkIfVariantAvailable(val) {
    if (!this.pageOptions.date.options)
      return true;
    return this.pageOptions.date.options.some(x => x.available && x.hasTicket == val);
  }

  getActiveOption() {
    return this.pageOptions.date.options.filter((o) => {
      return o.hasTicket === this.pageOptions.tickets.active
    })[0];
  }

  openQuantities() {
    this.isSelectBoxActive = !this.isSelectBoxActive;
  }

  openFooterQuantities() {
    this.isFooterSelectBoxActive = !this.isFooterSelectBoxActive;
  }

  track(category, action, label) {
    this.googleAnalyticsService.sendEvent('event', {
      'send_to': environment.ga_identifier,
      'event_category': category,
      'event_action': action,
      'event_label': label
    });
  }

  showSuccessAddInfo() {
    this.confirmItemAdded = true;
    this.checkoutButtonText = environment.added_checkout_text;
    setTimeout(() => {
      this.checkoutButtonText = environment.default_checkout_text;
    }, 500)
  }

  parseInt(qny) {
    return parseInt(qny)
  }

  checkSize() {
    if (this.datesSelector && this.datesSelector.nativeElement.getClientRects()[0]) {
      if (this.showDatesSelector !== this.datesSelector.nativeElement.getClientRects()[0].bottom > window.innerHeight) {
        this.showDatesSelector = this.datesSelector.nativeElement.getClientRects()[0].bottom > window.innerHeight;
        if (!this.showDatesSelector) this.isFooterSelectBoxActive = false;
      }
    }
  }

}

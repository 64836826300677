import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-related-products',
  templateUrl: './related-products.component.html',
  styleUrls: ['./related-products.component.scss']
})
export class RelatedProductsComponent implements OnInit {

  @ViewChild(SwiperDirective) mobileSwiper?: SwiperDirective;
  @Input() products: [];
  @Output() public onProductClick = new EventEmitter<any>();

  mobilePostsconfig: SwiperConfigInterface = {
    slidesPerView: 'auto',
    spaceBetween: 0,
    slidesPerGroup: 1,
    centeredSlides: false,
    loop: false,
    keyboard: true,
    mousewheel: false,
    loopFillGroupWithBlank: true,
  };

  constructor() { }

  ngOnInit() {
  }

  onItemClick(product) {
    if(environment.formBundleProductId !== product.id)
      this.onProductClick.emit(product);
  }

  ngOnChanges() {
    if (this.products && this.products.length > 1)
      this.sortProducts();

    setTimeout(() => {
      this.mobileSwiper.update();
    }, 500)
  }

  sortProducts() {
    if(environment.sortRelatedProductsDesc)
      this.products.sort((current: any, next: any) => current.variants[0].price - next.variants[0].price);
    else
      this.products.sort((current: any, next: any) => next.variants[0].price - current.variants[0].price);
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {CartService} from "../../services/cart.service";

@Component({
  selector: 'app-cart-badge',
  templateUrl: './cart-badge.component.html',
  styleUrls: ['./cart-badge.component.scss']
})
export class CartBadgeComponent implements OnInit {
  @Input() isColorInverted;
  cartItemsLength: number = 0;
  constructor(private cartService: CartService) { }

  ngOnInit() {
    this.cartItemsLength = this.cartService.getTotalQuantity(this.cartService.cart);
    this.cartService.cartSubject.subscribe((items:any) => {
      this.cartItemsLength = this.cartService.getTotalQuantity(this.cartService.cart);
    })
  }

}

import { Component, OnInit } from '@angular/core';
import {GlobalService} from "../../services/global.service";

@Component({
  selector: 'app-no-content',
  templateUrl: './no-content.component.html',
  styleUrls: ['./no-content.component.scss']
})
export class NoContentComponent implements OnInit {

  constructor(
    private globalService: GlobalService,
  ) { }

  ngOnInit() {
    this.globalService.setLoaded();
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'

@Pipe({
    name: 'dateShort'
})
export class DateShortPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        let date = moment(value).format('dddd MMMM DD, YYYY');
        return date;
    }

}
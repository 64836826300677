import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { BackendApiService } from '../../services/backend-api.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.scss'],
  animations: [
    trigger('hideForm', [
      state('true', style({
        opacity: 0,
      })),
      state('false', style({
        opacity: 1,
      })),
      transition('*=>true', animate('500ms')),
      transition('*=>false', animate('500ms'))
    ])
  ]
})
export class SignUpFormComponent implements OnInit {
  form: FormGroup;
  isSubmitConfirmed: boolean = false;
  isSubmitError: boolean = false;

  constructor(private fb: FormBuilder, private backendApiService: BackendApiService) { 
    this.form = this.fb.group({
      email: new FormControl('', { validators: [Validators.compose([Validators.required, Validators.email])], updateOn: 'blur' } ),
      firstname: new FormControl('', { validators: [Validators.compose([Validators.required])], updateOn: 'blur' }),
      lastname: new FormControl('', { validators: [Validators.compose([Validators.required])], updateOn: 'blur' }),
      phone: new FormControl('', { validators: [Validators.compose([Validators.required])], updateOn: 'blur' }),
      travelers: new FormControl('', { validators: [Validators.compose([Validators.required])], updateOn: 'blur' }),
      comments: new FormControl('', { validators: [Validators.compose([Validators.required])], updateOn: 'blur' }),
    });
  }

  ngOnInit() {
    
  }

  onSubmit() {
    this.form.markAsTouched();
    if (this.form.invalid) { return; }

    let email = this.form.get('email').value;
    let subject = 'New request';
    let html = this.getEmailHtml();
    this.backendApiService.sendEmail(email, html, subject).then(
      req => { 
        if(req){
          this.isSubmitConfirmed = req['result'] === 'success'; 
          this.isSubmitError = !this.isSubmitConfirmed;
        }
      },
      error => { this.isSubmitConfirmed = true; }
    );
  }

  getEmailHtml(){
    let email = this.form.get('email').value;
    let firstname = this.form.get('firstname').value;
    let lastname = this.form.get('lastname').value;
    let phone = this.form.get('phone').value;
    let travelers = this.form.get('travelers').value;
    let comments = this.form.get('comments').value;
    return `
      <p>Email - ${email}</p>
      <p>First Name - ${firstname}</p>
      <p>Last Name - ${lastname}</p>
      <p>Phone - ${phone}</p>
      <p>Travelers - ${travelers}</p>
      <p>Comments - ${comments}</p>
    `;
  }
}
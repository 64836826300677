import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'description'
})
export class DescriptionPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (args === 'short') {
      let short = /<short_description[^>]*>([\s\S]*?)<\/short_description>/gm.exec(value);
      if (short && short.length > 1) value = short[1];
    } else if (args === 'full') {
      // let fullDescriptionRule = /<short_description>.*<\/short_description>/gm;
      // value = value.replace(fullDescriptionRule, '<div>$1</div>');
    }
    // console.log(value);
    return value;
  }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isSignUpMode: false,
  brand: 'MIAMIOPEN',
  brandText: 'Miami Open',
  domain: 'endeavorco-dev.myshopify.com',
  shopifyaccesstoken: 'aef080f3de134943de9e02ee55cc203c',
  rollbar_token: '',
  ga_identifier: 'UA-131272618-1',
  colection_identifier: 'MIAMI-OPEN____',
  first_collection_id: 'Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzExMTE2MjQ1ODIxMg==',
  promoProductId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzM0MjU0NTQ3NTE4MDg=',
  formBundleProductId: '',
  amazon_lambda_token: '3Faywbjh5o3JZfn7M2wxe2G28U3rAZn27GdzndLb',
  amazon_lambda_gateway: 'https://v6gnxg6p7i.execute-api.us-west-2.amazonaws.com/default',

  title1: "",
  title2: "",

  annProject: false,
  miamiProject: true,
  nyfw: false,
  friese: false,

  firebase: {
    apiKey: "AIzaSyCvM68jrSPrtpdgF2meaBKRdiU5EPNaOrM",
    authDomain: "nyfw-28cd9.firebaseapp.com",
    databaseURL: "https://nyfw-28cd9.firebaseio.com",
    projectId: "nyfw-28cd9",
    storageBucket: "nyfw-28cd9.appspot.com",
    messagingSenderId: "404481823894",
    designers_collection: "dev/data"
  },

  videoUrls: {
    videoDesktop: {
      url: '',
      poster: ''
    },
    videoMobile: {
      url: '',
      poster: ''
    }
  },

  data: {
    phoneLink: 'tel:+1-844-325-0720',
    phone: '+1 (844) 325-0720',
    emailLink: 'mailto:experiences@endeavorco.com',
    email: 'experiences@endeavorco.com',
  },

  headerLinks: [
    {
      title: 'MIAMI OPEN',
      link: 'https://www.miamiopen.com/',
      target: ''
    },
  ],

  heroTitleHtml: `<div class="title-sm">
                    THE OFFICIAL VIP EXPERIENCES
                  </div>
                  <h1 class="title-lg">
                    MIAMI OPEN<br>
                    <span>March 18-31, 2019</span>
                  </h1>`,

  default_checkout_text: 'Add to cart',
  added_checkout_text: 'Added!',
  mainPhotoBackground: '',
  showSignupFooter: true,
  showAppLayoutFooter: false,
  showSignupFooterSkin2: false,
  showHeroBundle: true,
  heroPosterImg: '',
  showCartBadge: false,
  consiergeEmailSubject: 'Frieze VIP Experience Request',
  consiergeEmailText: '',
  showSignUpHome: false,
  hurryUpPopupBrand: 'MIAMI OPEN VIP',
  topHeaderLogoLink: '/',
  bottomHeaderLogoLink: '/',
  showStartingAtLabel: true,
  sortRelatedProductsDesc: true,

  topHeaderLogo: {
    themplate: ``,
    link: '/',
    target: ''
  },
  bottomHeaderLogo: {
    themplate: `<b>ENDEAVOR</b>EXPERIENCES`,
    link: '',
    target: ''
  },
  bundleExtendedForm: false,
  masonry: {
    masonryHintText: 'Taylor Hill/Getty Images for Augusta at Night',
    masonryTitleThemplate: `<p class="masonry-title-text">
                              Performance Highlights from
                              <span class="masonry-title-text__part">Sheryl Crow and Hootie and the Blowfish </span>
                              this past Event
                            </p>`,
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
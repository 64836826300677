import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from "@angular/router";

import { GoogleAnalyticsService } from "../../services/google-analytics.service";
import { ShopifyService } from 'src/app/services';
import { ParseService } from 'src/app/services/parse.service';

import { environment } from '../../../environments/environment';
import { CacheService } from 'src/app/services/cache.service';

@Component({
  selector: 'app-hero-banner',
  templateUrl: './hero-banner.component.html',
  styleUrls: ['./hero-banner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeroBannerComponent implements OnInit {

  @Input() product: any[];

  @ViewChild('videoDesktop') videoDesktop: any;
  @ViewChild('videoMobile') videoMobile: any;

  environmentData = environment;

  videoUrls = environment.videoUrls;
  mainPhoto = environment.mainPhotoBackground;
  showHeroBundle = environment.showHeroBundle;
  heroPosterImg = environment.heroPosterImg;
  heroTitleHtml = environment.heroTitleHtml;

  constructor(
    private shopifyService: ShopifyService,
    private parseService: ParseService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private cacheService: CacheService,
  ) { }

  ngOnInit() {
    this.cacheService.currentProducts.subscribe(value => {
      if (value)
        window.innerWidth > 1200 ? this.playVideo('videoDesktop') : this.playVideo('videoMobile');
    })
  }

  ngAfterViewInit() {
    window.innerWidth > 1200 ? this.initVideo('videoDesktop') : this.initVideo('videoMobile'); //TODO
  }

  initVideo(type) {
    if (!this.videoUrls)
      return;
    this[type].nativeElement.poster = this.videoUrls[type].poster;
  }

  playVideo(type) {
    if (!this.videoUrls)
      return;
    this[type].nativeElement.src = this.videoUrls[type].url;
    this[type].nativeElement.load();
    this[type].nativeElement.play();
  }

  track(category, action, label) {
    this.googleAnalyticsService.sendEvent('event', {
      'send_to': environment.ga_identifier,
      'event_category': category,
      'event_action': action,
      'event_label': label
    });
  }

  gotoDetailProductPage(product) {
    this.router.navigate(['/event', product.id]);
    this.track(`${environment.brand}: Purchase Package`, 'Reserve from home page', product.title);
  }

}

import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-cart-empty',
  templateUrl: './cart-empty.component.html',
  styleUrls: ['./cart-empty.component.scss']
})
export class CartEmptyComponent implements OnInit {
  @Output() hide = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  hideCart() {
    this.hide.emit();
  }

}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-masonry',
  templateUrl: './masonry.component.html',
  styleUrls: ['./masonry.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MasonryComponent implements OnInit {
  environment = environment;

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnChanges, OnInit {
  @Input() show;
  @Input() text;
  @Input() styles;
  @Output() public hide: EventEmitter<any> = new EventEmitter<any>();
  isShow: boolean = false;
  customStyles: any;
  timeoutObj: any;
  constructor() { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    this.showToggle(changes.show.currentValue);
    this.customStyles = this.styles;
  }

  showToggle(val){
    this.isShow = val;
    if (this.isShow)
      this.timeoutObj = setTimeout(() => {
        this.isShow = !val;
        this.hide.emit();
      }, 4000);
    else if (this.timeoutObj){
      clearTimeout(this.timeoutObj);
      this.timeoutObj = null;
      this.hide.emit();
    }
  }
}

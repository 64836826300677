import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html'
})
export class FaqComponent implements OnInit {
  environment = environment;
  window = window;
  constructor(private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.globalService.setLoaded();
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationEnd } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { BackendApiService } from '../../services/backend-api.service';

@Component({
  selector: 'vip-footer',
  templateUrl: './vip-footer.component.html',
  styleUrls: ['./vip-footer.component.scss'],
})
export class VipFooterComponent implements OnInit {
  isSubmitConfirmed: boolean = false;
  form: FormGroup;
  emailPattern: string = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  formSubmitted: boolean = false;
  isVipPage: boolean = false;
  constructor(private route: ActivatedRoute,
              private fb: FormBuilder,
              private backendApiService: BackendApiService,
              private router: Router
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.isVipPage = event.url.indexOf('vip-experience') > -1;
      }
    });
    this.form = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(this.emailPattern)])],
    });
  }

  ngOnInit() {}

  gotoPage(page) {
    this.router.navigate([page]);
  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.form.invalid) { return }
    this.isSubmitConfirmed = true;
    this.backendApiService.subscribeEmail(this.form.get('email').value).then(
      success => {},
      error => console.warn(error)
    );
  }

  clearErr() {
    this.formSubmitted = false;
  }
}
import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { ShopifyService } from "../../services/shopify.service";
import { environment } from "../../../environments/environment";
import { GoogleAnalyticsService } from "../../services/google-analytics.service";
import { CartService } from "../../services/cart.service";
import { ParseService } from "../../services/parse.service";
import { Router } from "@angular/router";
import {
  trigger,
  state,
  style,
  animate,
  transition,
  sequence
} from '@angular/animations';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./overlay.scss', './cart.component.scss', './custom-input.scss'],
  animations: [
    trigger('removeProduct', [
      state('remove', style({
        left: '110%',
        marginTop: '-200px'
      })),
      transition('* => remove', sequence([
        style({ left: '0' }),
        animate('0.3s ease-out', style({
          left: '110%',
          marginTop: '0'
        })),
        animate('0.3s ease-out', style({
          left: '110%',
          marginTop: '-200px'
        }))
      ]))
    ]),
  ],
})
export class CartComponent implements OnInit {
  @Input() isOpen;
  @Output() toggle = new EventEmitter();
  item: any; //TODO: remove
  totalPrice: number = 0;
  qtyErrorItemIndex: number = -1;
  isCheckoutInProgress: boolean = false;
  formSubmitted: boolean = false;
  cart = [];
  tooltipStyles: any = { 'min-width': '300px', 'top': '-65px', 'max-width': '100%' };
  qtyErrorText: string = `To purchase experiences for a group of 6 or more, <a href="${environment.data.email}"><b>Email</b></a> or 
                          <a href="${environment.data.phoneLink}"><b>Call</b></a> us at<b> ${environment.data.phone}</b>`;
  isCheckoutDisabled: boolean = false;
  brandConfig = {
    brand_url: `https://${window.location.hostname}`,
    brand: environment.brand
  };

  constructor(@Inject(DOCUMENT) private document: any,
    private shopifyService: ShopifyService,
    private parseService: ParseService,
    private router: Router,
    private cartService: CartService,
    private googleAnalyticsService: GoogleAnalyticsService) {
    router.events.subscribe((val) => {
      if (this.isOpen)
        this.toggle.emit();
    });
  }

  ngOnInit() {
    this.cart = this.cartService.cart;
    this.totalPrice = this.getTotalPrice(this.cart);
    this.isCheckoutDisabled = this.isWrongItemsAmount(this.cart);
    this.cartService.cartSubject.subscribe((items: any) => {
      this.cart = items;
      this.totalPrice = this.getTotalPrice(items);
      this.isCheckoutDisabled = this.isWrongItemsAmount(items);
      if (!this.isOpen)
        this.toggle.emit();
    });
  }

  hide() {
    this.toggle.emit();
  }

  createCheckout() {
    this.formSubmitted = true;
    this.isCheckoutInProgress = true;
    let data = this.lineItemsToAdd();
    this.shopifyService.createCheckout(data, this.attributesArray()).then(
      (createdOrder) => {
        let sign = (createdOrder.webUrl.indexOf('?') != -1) ? '&' : '?'; //check if the link already has query params
        this.document.location.href = createdOrder.webUrl + `${sign}brand=${environment.brand}`;
        this.cartService.clearCart();
      }
    );
  }

  onItemUpdate(item) {
    this.cartService.cart = item;
    this.totalPrice = this.updateTotalPrice(this.cart);
    this.isCheckoutDisabled = this.isWrongItemsAmount(this.cart);
  }

  removeItemAnimation(item) {
    item.removed = 'remove'; // animation name
  }

  removeItem(item) {
    if (!item.removed)
      return;
    this.cartService.removeItem(item);
    this.cart = this.cartService.cart;
    this.totalPrice = this.updateTotalPrice(this.cart);
    this.isCheckoutDisabled = this.isWrongItemsAmount(this.cart);
    this.track(`${environment.brand}: edit cart`, 'Remove product from cart', item.product.title);
    this.googleAnalyticsService.removeFromCart(item.product, item.quantity);
  }

  updateQuantity(quantity, item, index) {
    let newValue = this.parseInt(quantity);
    let error = newValue > 5 || newValue < 1 || isNaN(newValue);
    if (error) {
      item.quantity = newValue > 5 ? 5 : 1;
      this.qtyErrorItemIndex = index;
      this.onItemUpdate(item);
      return;
    }
    item.quantity = newValue;
    this.track(`${environment.brand}: edit cart`, 'Edit qty of a product in cart', item.product.title);
    this.onItemUpdate(item);
  }

  incrementQuantity(item, increment, index) {
    let newValue = item.quantity + increment;
    this.updateQuantity(newValue, item, index);
  }

  attributesArray() {
    let attrArray = [];
    attrArray.push({
      key: 'brand',
      value: this.brandConfig.brand
    });
    return attrArray;
  }

  track(category, action, label) {
    this.googleAnalyticsService.sendEvent('event', {
      'send_to': environment.ga_identifier,
      'event_category': category,
      'event_action': action,
      'event_label': label
    });
  }

  clearErr() {
    this.formSubmitted = false;
  }

  parseInt(n) {
    return parseInt(n)
  }

  getTotalPrice(items) {
    return this.updateTotalPrice(items);
  }

  hideTooltip(field) {
    this[field] = -1;
  }

  updateTotalPrice(items) {
    let price = 0;
    items.forEach((item) => {
      let itemPrice = this.parseInt(item.product.activeOption.price);
      let itemQuantity = this.parseInt(item.quantity);
      price += (itemPrice * itemQuantity);
    });
    return price;
  }

  lineItemsToAdd() {
    let lineItems = [];
    this.cart.forEach((item) => {
      let attr = {
        variantId: item.product.activeOption.id,
        quantity: item.quantity,
      };
      lineItems.push(attr)
    });
    return lineItems;
  }

  isWrongItemsAmount(items) {
    return !!items.filter((item) => {
      return item.quantity > 5
    }).length
  }
}

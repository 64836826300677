import { Component, OnInit, Input } from '@angular/core';

import { trigger, state, style, animate, transition } from '@angular/animations';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bundle',
  templateUrl: './bundle.component.html',
  animations: [
    trigger('simpleFadeAnimation', [

      // the "in" style determines how the element looks when it is visible.
      // the style name "in" must match the value of the [@simpleFadeAnimation]="'in'" attribute in the template
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        // the styles start from this point when the element appears
        style({ opacity: 0 }),
        // and animate toward the "in" state above
        animate(800)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        // fading out uses a different syntax, with the "style" being passed into animate()
        animate(800, style({ opacity: 0 })))
    ]),
  ],
  styleUrls: ['./bundle.component.scss']
})
export class BundleComponent implements OnInit {
  @Input() public product: any;
  @Input() public isTopProduct: boolean;
  imgIndex: number = -1;
  environment = environment;
  constructor() { }

  ngOnInit() {
    // console.log(this.variant);
  }

  ngOnChanges() {
    if (this.product)
      this.checkImagesForVideo();
  }

  checkImagesForVideo() { 
    let i = 0; 
    while (this.imgIndex < 0 || i > this.product.images.length) {
      this.imgIndex = this.product.images[i] && this.product.images[i].altText
        && this.product.images[i].altText.toLowerCase().indexOf(".mp4") > 0 ? -1 : i;
      i++;
    }
  }

}

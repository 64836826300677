import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-quantity-selector',
  templateUrl: './quantity-selector.component.html',
  styleUrls: ['./quantity-selector.component.scss']
})
export class QuantitySelectorComponent implements OnInit {
  // quantity: number;
  isOpened: boolean = false;
  @Input() public quantity: any;
  @Output() public onUpdateQuantityClick: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  updateQuantity(qny) {
    this.onUpdateQuantityClick.emit(qny)
    this.isOpened = false;
  }

  open() {
    this.isOpened = !this.isOpened;
  }

}

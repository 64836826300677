import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html'
})
export class AboutComponent implements OnInit {
  environment = environment;
  constructor(
    private router: Router,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.globalService.setLoaded();
  }

  gotoPage(page) {
    this.router.navigate([page]);
  }
}

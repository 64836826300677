import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { BackendApiService } from '../../services/backend-api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'footer-signup-skin-2',
  templateUrl: './footer-signup-skin-2.component.html',
  styleUrls: ['./footer-signup-skin-2.component.scss'],
})

export class FooterSignupSkin2Component implements OnInit {
  environment = environment;
  isSubmitConfirmed: boolean = false;
  form: FormGroup;
  emailPattern: string = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  formSubmitted: boolean = false;
  constructor(private route: ActivatedRoute,
              private fb: FormBuilder,
              private backendApiService: BackendApiService,
              private router: Router
  ) {
    this.form = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(this.emailPattern)])],
    });
  }

  ngOnInit() {}

  gotoPage(page) {
    this.router.navigate([page]);
  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.form.invalid) { return }
    this.isSubmitConfirmed = true;
    this.backendApiService.subscribeEmail(this.form.get('email').value).then(
      success => { console.log(success)},
      error => console.warn(error)
    );
  }

  clearErr() {
    this.formSubmitted = false;
  }
}

import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html'
})
export class TermsComponent implements OnInit {
  link = window.location.href;
  constructor(private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.globalService.setLoaded();
  }
}

import { Component, Renderer2 } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { BackendApiService } from '../../services/backend-api.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up-modal',
  templateUrl: './sign-up-modal.component.html',
  styleUrls: ['./sign-up-modal.component.scss']
})

export class SignUpModalComponent {
  isSubmitConfirmed: boolean = false;
  form: FormGroup;
  emailPattern: string = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  formSubmitted: boolean = false;
  isModalEnabled: boolean = !localStorage.getItem('SIGN-UP_MODAL');
  showModal: boolean = false;
  mouseoutListener: any;
  constructor(
    private fb: FormBuilder,
    private renderer: Renderer2,
    private backendApiService: BackendApiService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router
  ) {
    this.form = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(this.emailPattern)])],
    });
  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.form.invalid) { return }
    this.isSubmitConfirmed = true;
    let email = this.form.get('email').value;
    this.backendApiService.subscribeEmail(email).then(
      success => {
        this.track(`${environment.brandText}: email capture popup`, 'Email captured');
      },
      error => console.warn(error)
    );
  }

  closeModal() {
    this.showModal = false;
    localStorage.setItem('SIGN-UP_MODAL', 'true');
    this.track(`${environment.brandText}: email capture popup`, 'User dismissed popup');
    if (this.mouseoutListener) this.mouseoutListener(); //unsubscribe
  }

  clearErr() {
    this.formSubmitted = false;
  }

  ngOnInit() {
    if (this.isModalEnabled) {
      if (window.innerWidth < 1200) setTimeout(() => {
        this.showPopUp()
      }, 50000);
      this.mouseoutListener = this.renderer.listen('document', 'mouseout', (event) => {
        if (event.clientY <= 0) {
          this.showPopUp()
        }
      });
    }
  }

  showPopUp() {
    this.showModal = true;
    this.track(`${environment.brandText}: email capture popup`, 'Popup displyed');
  }

  goToLink(link) {
    this.showModal = false;
    this.router.navigate(['/', link]);
  }

  track(category, action) {
    this.googleAnalyticsService.sendEvent('event', {
      'send_to': environment.ga_identifier,
      'event_category': category,
      'event_action': action
    });
  }

}

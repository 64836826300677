import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'

@Pipe({
    name: 'dateFull'
})
export class DateFullPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        let date = moment(value).format('dddd, MMMM Do, YYYY');
        return date;
    }

}
import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy-ext.component.html'
})
export class PrivacyExtComponent implements OnInit {
  constructor(private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.globalService.setLoaded();
  }
}

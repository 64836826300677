import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { BackendApiService } from 'src/app/services/backend-api.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-email-input',
  templateUrl: './email-input.component.html',
  styleUrls: ['./email-input.component.scss']
})
export class EmailInputComponent implements OnInit {
  environment = environment;
  form: FormGroup;
  isSubmitConfirmed: boolean = false;
  isSubmitError: boolean = false;

  constructor(
    private fb: FormBuilder,
    private backendApiService: BackendApiService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.form = this.fb.group({
      email: new FormControl('', { validators: [Validators.compose([Validators.required, Validators.email])], updateOn: 'blur' }),
      fullname: new FormControl('', { validators: [Validators.compose([Validators.required])], updateOn: 'blur' }),
      phone: new FormControl('', { validators: [Validators.compose([Validators.required])], updateOn: 'blur' })
    });
  }

  ngOnInit() {
    
  }

  onSubmit() {
    this.form.markAsTouched();
    if (this.form.invalid) { return; }

    let email = this.form.get('email').value;
    let subject = environment.consiergeEmailSubject;
    let html = this.getEmailHtml();
    this.googleAnalyticsService.sendEvent('event', { 'Event_action': 'Form Submit', 'Event_category': `${environment.brand}: Submit Inquiry`, 'Event_label': `${environment.brand} Lead Gen` });
    this.backendApiService.sendEmail(email, html, subject).then(
      req => {
        if (req) {
          this.isSubmitConfirmed = req['result'] === 'success';
          this.isSubmitError = !this.isSubmitConfirmed;
        }
      },
      error => { this.isSubmitConfirmed = true; }
    );
  }

  getEmailHtml() {
    let email = this.form.get('email').value;
    let fullname = this.form.get('fullname') ? `<p>Full Name - ${this.form.get('fullname').value} </p>` : '';
    let phone = this.form.get('phone') ? `<p>Phone - ${this.form.get('phone').value} </p>` : '';
    return `
      <p>Hi, I'd like to learn more about the Frieze NY Collector Experience package, please reach out to me at</p>
      <p>Email - ${email}</p>
      ${fullname}
      ${phone}
    `;
  }
}

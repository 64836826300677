import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  Injectable,
  Injector,
  InjectionToken,
  NgModule,
  ErrorHandler,
  enableProdMode
} from '@angular/core';

import { GtagModule } from 'angular-gtag';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';
//import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ShopifyService } from './services/shopify.service';
import { ProductService } from './services/product.service';
import { GlobalService } from './services/global.service';
import { ModalService } from './services/modal.service';
import { NewParseService } from './services/new.parse.service';
import { ParseService } from './services/parse.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { CacheService } from './services/cache.service';
import { BackendApiService } from './services/backend-api.service';

//import { LoaderComponent } from './components/loader/loader.component';
import { LoaderCenteredComponent } from './components/loader-centered/loader-centered.component';
import { CookiesBannerComponent } from './components/cookies-banner/cookies-banner.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { CartBadgeComponent } from './components/cart-badge/cart-badge.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { PostEventComponent } from './pages/post-event/post-event-page.component';
import { PrivacyExtComponent } from './pages/privacy-ext/privacy-ext.component';
import { FaqComponent } from './pages/faq/faq.component';
import { PurchaseComponent } from './pages/purchase/purchase.component';
import { CookieComponent } from './pages/cookie/cookie.component';
//import { CartPageComponent } from './pages/cart/cart.component';
import { CartComponent } from './components/cart/cart.component';
import { BundleComponent } from './components/bundle/bundle.component';
import { SignUpComponent } from './components/signup/signup.component';
//import { DatePickerComponent } from './components/date-picker/date-picker.component';
//import { ContactComponent } from './pages/contact/contact.component';
import { BundlePlaceholderComponent } from './components/bundle-placeholder/bundle-placeholder.component';
//import { DetailProductGuardService } from './pages/product-page/detail-product-page.guard';
//import { RelatedProductsResolver } from './components/related-products/related-product.resolve';
//import { RelatedProductsGuardService } from './components/related-products/related-products.guard';
import { RelatedProductsComponent } from './components/related-products/related-products.component';
//import { RelatedProductsDpComponent } from './components/related-products/related-products-dp.component';
import { TooltipComponent } from './components/tooltip/tooltip.component'; 
import { HurryupPopupComponent } from './components/hurryup-popup/hurryup-popup.component';
import { LoaderTransparentComponent } from './components/loader-transparent/loader-transparent.component';
import { CaruselComponent } from './components/carusel/carusel.component';
import { ProductPageComponent } from './pages/product-page/product-page.component';
//import { SwiperViewComponent } from './components/swiper/swiper.component';
import { QuantitySelectorComponent } from './components/quantity-selector/quantity-selector.component';
import { CartEmptyComponent } from './components/cart-empty/cart-empty.component';
import { FooterSignupComponent } from './components/footer-signup/footer-signup.component';
import { FormRightSectionComponent } from './components/form-right-section/form-right-section.component';
import { SignUpFormComponent } from './components/signup-form/signup-form.component';
import { FooterSignupSkin2Component } from './components/footer-signup-skin-2/footer-signup-skin-2.component';
//import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { SignUpModalComponent } from './components/sign-up-modal/sign-up-modal.component';

import { SanitizeHtmlPipe } from './pipes/sanitizehtml.pipe';
import { DescriptionPipe } from './pipes/description.pipe';

import * as Rollbar from 'rollbar';
import { environment } from '../environments/MIAMIOPEN/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NoContentComponent } from './pages/404/no-content.component';
import { ModalsModule } from './modals/modals.module';
import './app.component.scss';
import { VipFooterComponent } from './components/vip-footer/vip-footer.component';
import { HeroBannerComponent } from './components/hero-banner/hero-banner.component';
import { CheckoutWithPriceComponent } from './components/checkout-with-price/checkout-with-price.component';
import { EmailInputComponent } from './components/email-input/email-input.component';
import { from } from 'rxjs';
import { DateFullPipe } from './pipes/date-full.pipe';
import { DateShortPipe } from './pipes/date-short.pipe';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { MasonryComponent } from './components/masonry/masonry.component';

if(environment.production) {
  enableProdMode();
}

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

export let rollbarConfig = {
  accessToken: environment.rollbar_token,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: environment.production,
  environment: 'production'
}

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) { }

  handleError(err: any): void {
    var rollbar = this.injector.get(RollbarService);
    rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    //LoaderComponent,
    LoaderCenteredComponent,
    CookiesBannerComponent,
    MobileMenuComponent,
    NoContentComponent,
    CartBadgeComponent,
    PostEventComponent,
    SignUpModalComponent,
    HomeComponent,
    BundleComponent,
    //DatePickerComponent,
    //ContactComponent,
    BundlePlaceholderComponent,
    AboutComponent,
    TermsComponent,
    PrivacyComponent,
    FooterSignupComponent,
    FooterSignupSkin2Component,
    VipFooterComponent,
    HurryupPopupComponent,
    PrivacyExtComponent,
    SignUpFormComponent,    
    FaqComponent,
    CookieComponent,
    PurchaseComponent,
    CartComponent,
    FormRightSectionComponent,
    SignUpComponent,
    SanitizeHtmlPipe,
    LoaderTransparentComponent,
    CaruselComponent,
    TooltipComponent,
    //CartPageComponent,
    DescriptionPipe,
    DateFullPipe,
    DateShortPipe,
    ProductPageComponent,
    RelatedProductsComponent,
    //RelatedProductsDpComponent,
    //SwiperViewComponent,
    QuantitySelectorComponent,
    CartEmptyComponent,
    HeroBannerComponent,
    CheckoutWithPriceComponent,
    EmailInputComponent,
    CheckoutComponent,
    MasonryComponent,
    //ConfirmModalComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollToModule.forRoot(),
    GtagModule.forRoot({ trackingId: environment.ga_identifier, trackPageviews: true }),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalsModule,
    SwiperModule,
    //LazyLoadImageModule,
  ],
  providers: [
    ShopifyService, 
    ProductService, 
    GlobalService,
    ModalService, 
    ParseService, 
    GoogleAnalyticsService,
    NewParseService,
    //DetailProductGuardService,
    //RelatedProductsGuardService,
    //RelatedProductsResolver,
    CacheService,
    BackendApiService,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

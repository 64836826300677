import { Component } from '@angular/core';

@Component({
  selector: 'form-right-section',
  templateUrl: './form-right-section.component.html',
  styleUrls: ['./form-right-section.component.scss'],
})
export class FormRightSectionComponent{
  isSubmitConfirmed: boolean = false;
  isSubmitError: boolean = false;

  constructor() { 
  }
}
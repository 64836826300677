import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-carusel',
  templateUrl: './carusel.component.html',
  styleUrls: ['./carusel.component.scss'],
})
export class CaruselComponent {
  @ViewChild(SwiperDirective) swiperRef?: SwiperDirective;
  config: SwiperConfigInterface = {
    slidesPerView: 1,
    spaceBetween: 0,
    slidesPerGroup: 1,
    loop: false,
    keyboard: true,
    mousewheel: false,
    pagination: true,
    navigation: {
      nextEl: '.swiper-button-wrapper-next',
      prevEl: '.swiper-button-wrapper-prev',
    },
    preloadImages: false
  };
  @Input() images;
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  previewImages = [];
  timeOut: any;
  activeImageIndex = 0;
  videoIsPlaying: boolean = false;
  constructor() { }


  ngOnDestroy() {}

  ngOnChanges() {
    this.activeImageIndex = 0;
    this.getPreviewImages();
    this.checkImagesForVideo();
    setTimeout(() => {
      this.swiperRef.update();
    }, 500)
  }

  selectImage(img) {
    this.activeImageIndex = this.images.findIndex(function (o) { return o.id === img.id; })
  }

  shiftImages(index) {
    let arr = [];
    let max = this.images.length > 3 ? 4 : this.images.length;

    for(let i = index; i < index+max; i++) {
      if(this.images[i]) {
        arr.push(this.images[i])
      } else {
        arr.push(this.images[i - this.images.length])
      }
    }
    return arr;
  }

  indexChange(index) {
    this.previewImages =  this.shiftImages(index)
  }

  checkImagesForVideo() {
    for(let img of this.images){
      img.isVideo = img.altText && img.altText.toLowerCase().indexOf(".mp4") > 0;
    }
  }

  playVideo() {
    if (this.videoplayer.nativeElement.paused){
      this.videoplayer.nativeElement.play();
      this.videoIsPlaying = true;
    }
    else{
      this.videoplayer.nativeElement.pause();
      this.videoIsPlaying = false;
    }
  }

  videoIsLoaded() {
    if(this.videoplayer && this.videoplayer.nativeElement.readyState === 4)
      return true;
    this.timeOut = setTimeout(() => this.videoIsLoaded(), 300);
  }

  getPreviewImages() {
    let index = this.activeImageIndex;
    this.previewImages = this.shiftImages(index)
  }

}

import { Component, Input, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-checkout-with-price',
  templateUrl: './checkout-with-price.component.html',
  styleUrls: ['./checkout-with-price.component.scss']
})
export class CheckoutWithPriceComponent implements OnInit {

  environmentData = environment;

  @Input() product: any = {};

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html'
})
export class CookieComponent implements OnInit {
  constructor(private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.globalService.setLoaded();
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bundle-placeholder',
  templateUrl: './bundle-placeholder.component.html',
  styleUrls: ['./bundle-placeholder.component.scss'],
})
export class BundlePlaceholderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

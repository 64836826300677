import { Injectable } from '@angular/core';
import { ShopifyService } from '../services/shopify.service';
import { ParseService } from '../services/parse.service';
import { environment } from '../../environments/environment';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  products: any[];
  queryPromise: Promise<any>;
  private dataSource = new BehaviorSubject([]);
  currentProducts = this.dataSource.asObservable();

  constructor(
    private shopifyService: ShopifyService,
    private parseService: ParseService
  ) {
    this.queryPromise = this.shopifyService.getProductsByCollectionId(environment.first_collection_id).then(data => {
      if (data && data.products) {
        this.products = data.products.map(product => this.parseService.toProduct(product));
        this.setData(this.products);
        this.queryPromise = null;
      }
    });
  }

  setData(data) {
    this.dataSource.next(data);
  }

  getProductById(id): Promise<any> {
    if (this.queryPromise)
      return this.queryPromise.then(data => {
        let product = this.products.filter(product => product.id === id);
        return product ? product[0] : null;
      });

    let product = this.products.filter(product => product.id === id);
    return new Promise((resolve, reject) => product ? resolve(product[0]) : resolve(null));
  }
}
import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html'
})
export class PrivacyComponent implements OnInit {
  constructor(
    private router: Router,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.globalService.setLoaded();
  }

  gotoPage(page) {
    this.router.navigate([page]);
  }
}

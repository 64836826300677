import * as moment from "moment";
import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";

@Injectable()

export class ParseService {
  constructor() {
  }

  toProduct(product) {
    product.location = this.getShowLocation(product);
    product.tag = this.getTag(product);
    product.secondary_title = this.getSecondaryTitle(product);
    //product.variants = this.setVariants(product.variants);
    product.isPromoted = product.descriptionHtml.indexOf('<promoted>') > -1;
    product.min_price = this.getProductMinPrice(product);
    return product;
  }

  getShowLocation(product: any) {
    let productData = Object.assign({}, product);
    let description: string = productData.descriptionHtml;
    let locationNamePattern = /<location_name[^>]*>([\s\S]*?)<\/location_name>/gm;
    let locationAddressPattern = /<location_address[^>]*>([\s\S]*?)<\/location_address>/gm;
    let locationUrlPattern = /<location_url[^>]*>([\s\S]*?)<\/location_url>/gm;

    let isLocationAdded = description.includes('location_name');
    if (isLocationAdded) {
      let location_name, location_address, location_url;
      let location_name_exec = locationNamePattern.exec(description);
      if (location_name_exec && location_name_exec.length > 1) location_name = location_name_exec[1];
      let location_address_exec = locationAddressPattern.exec(description);
      if (location_address_exec && location_address_exec.length > 1) location_address = location_address_exec[1];
      let location_url_exec = locationUrlPattern.exec(description);
      if (location_url_exec && location_url_exec.length > 1) location_url = location_url_exec[1];
      return {
        location_name: location_name ? location_name : '',
        location_address: location_address ? location_address : '',
        location_url: location_url ? location_url : '',
      };
    } else return {};
  }

  setVariants(variants) {
    let dates = {};
    variants.forEach((variant) => {
      let productFirstOption = variant.title.split(' / ')[0].toString(); // Returns 2019 March 27
      let productSecondOption = '';
      if (variant.title.split(' / ')[1])
        productSecondOption = variant.title.split(' / ')[1].toString(); // Returns Brooklyn
      let convertedData = this.convertDateToIsoFormat(productFirstOption);
      let isoDate = moment(convertedData).format('YYYY-MM-DD');
      if (!dates[isoDate]) dates[isoDate] = this.productDate(convertedData);
      dates[isoDate].options.push(this.setDateOptions({ variant, productFirstOption, productSecondOption }));
    });
    return dates;
  }

  setDateOptions(data) {
    return {
      productFirstOption: data.productFirstOption,
      productSecondOption: data.productSecondOption,

      available: data.variant.available,
      hasTicket: data.productSecondOption.indexOf('ADD TICKETS TO MY ORDER') !== -1 ? false : true,
      id: data.variant.id,
      price: parseInt(data.variant.price),
      isSelected: false
    }
  }

  productDate(convertedData) {
    return {
      options: [],
      isSelected: false,
      formatted: {
        isoDate: moment(convertedData).format('YYYY-MM-DD'),
        fullHumanDate: {
          date: moment(convertedData).format('MMMM, D'),
          dayOfWeek: moment(convertedData).format('dddd'),
        },
        shortHumanDate: {
          date: moment(convertedData).format('MMM D'),
          dayOfWeek: moment(convertedData).format('ddd'),
        }
      }
    };
  }

  convertDateToIsoFormat(date) {
    let d = date.split(' ').reverse().toString().replace(/,/g, ' ');
    return new Date(d);
  }

  getHighestPriceProduct(variantByCollection) {
    let collections = [].concat(variantByCollection);
    return collections.sort(function (a, b) {
      return (a.variantByCollection.price < b.variantByCollection.price) ? -1 : ((a.variantByCollection.price > b.variantByCollection.price) ? 1 : 0);
    })[0]
  }

  removeHighestPriceProduct(products, id) {
    return products.filter(product => product.id !== id);
  }

  getProductMinPrice(product) {
    // let minPrice;
    // let allPrices = [];
    // let dates = product.dates || {};
    // for (var key in dates) {
    //   let dayOptions = dates[key].options || [];
    //   dayOptions.forEach((o) => { allPrices.push(o.price) })
    // }
    // minPrice = allPrices.sort((a, b) => a - b)[0];
    return product.variants.reduce((prev, curr) => { return Number(curr.price) > prev ? curr.price : prev });
  }

  getTag(product) {
    let tagPattern = /<top_label[^>]*>([\s\S]*?)<\/top_label>/gm;
    let tag = tagPattern.exec(product.descriptionHtml);
    if (tag && tag.length > 1) return tag[1];
  }

  getSecondaryTitle(product) {
    let pattern = /<secondary_title[^>]*>([\s\S]*?)<\/secondary_title>/gm;
    let secondaryTitle = pattern.exec(product.descriptionHtml);
    if (secondaryTitle && secondaryTitle.length > 1) return secondaryTitle[1];
  }
}

import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html'
})
export class PurchaseComponent implements OnInit {
  environmentData = environment;
  constructor(private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.globalService.setLoaded();
  }
}

import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { GlobalService } from '../../services/global.service';
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from '../../../environments/environment';
import { SwiperDirective, SwiperConfigInterface } from "ngx-swiper-wrapper";

@Component({
  selector: 'app-post-event-page',
  templateUrl: './post-event-page.component.html',
  styleUrls: ['./article.component.scss', './post-event-page.component.scss']
})
export class PostEventComponent implements OnInit {
  videoUrls = environment.videoUrls;
  environmentData = environment;
  config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: true,
    mousewheel: false,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    }
  };
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  @ViewChild('videoDesktop') videoDesktop: any;

  constructor(
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private globalService: GlobalService
  ) { }

  ngOnInit() {
    this.globalService.setLoaded();
  }

  ngAfterViewInit() {
    if (this.videoUrls)
      this.playVideo('videoDesktop');
  }

  gotoPage(page) {
    this.router.navigate([page]);
  }

  playVideo(type) {
    this[type].nativeElement.src = this.videoUrls[type].url;
    this[type].nativeElement.poster = this.videoUrls[type].poster;
    this[type].nativeElement.load();
    this[type].nativeElement.play();
  }
}
import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class BackendApiService {
  constructor(private http: HttpClient) { }


  subscribeEmail(email, firstName = "empty", lastName = "empty", phone = ""): Promise<any> {
    let query = this.getCustomerQuery(email, firstName, lastName, phone);
    query['method'] = 'subscribe';
    query['environment'] = environment.production ? 'prod' : 'dev';
    let queryString = JSON.stringify(query);
    let headers = new HttpHeaders({
      'x-api-key': environment.amazon_lambda_token,
      'Content-Type': 'application/json'
    });
    const formUrl = `${environment.amazon_lambda_gateway}/eep-subscriptions`;
    return this.http.post(formUrl, queryString, { headers: headers }).toPromise();
  }

  sendEmail(to, message, subject): Promise<any> {
    let query = {
      to: to,
      message: message,
      subject: subject,
      method: 'sendEmail',
      environment: environment.production ? 'prod' : 'dev'
    };
    let queryString = JSON.stringify(query);
    let headers = new HttpHeaders({
      'x-api-key': environment.amazon_lambda_token,
      'Content-Type': 'application/json'
    });
    const formUrl = `${environment.amazon_lambda_gateway}/eep-subscriptions`;
    return this.http.post(formUrl, queryString, { headers: headers }).toPromise();
  }

  // subscribeEmail(formData) {
  //   const headers = new HttpHeaders();
  //   headers.append('Content-Type', 'multipart/form-data');
  //   headers.append('Accept', 'application/json');
  //   // dmytro's personal
  //   // const formUrl = `https://script.google.com/macros/s/AKfycbxZmiBgQMutN2a65J11afd7DDTcXqvKuEMuu4gETKwACBK3n10/exec`;
  //   // bkstg
  //   const formUrl = `https://script.google.com/macros/s/AKfycbwUjrw6ehrgGIWlICkHuq9Ef76stVEohXveJDDnVDhIrLMo4tg/exec`;
  //   return this.http.post(formUrl, formData, {headers: headers});
  // }

  private getCustomerQuery(email, firstName, lastName, phone) {
    return {
      "customer": {
        "id": Math.ceil(Math.random() * 999999999),
        "email": email,
        "first_name": firstName,
        "last_name": lastName,
        "phone": phone,
        "accepts_marketing": true,
        "tags": [environment.brand]
      }
    };
  }

  // loadEmailsBulk(i) {
  //   if (i == data.length)
  //     return;
  //   let arr = data[i].split(',');
  //   let email = arr[0];
  //   let phone = arr[1] && arr[1] != 'undefined' ? arr[1] : "";
  //   let firstName = "";
  //   let lastName = "";
  //   if (arr.length > 1) {
  //     let fullName = arr[2].split(' ');
  //     firstName = fullName[0] ? fullName[0] : '';
  //     lastName = fullName.length > 1 ? fullName[1] : '';
  //   }
  //   console.log(i, email, firstName, lastName, phone);
  //   this.subscribeEmail(email, firstName, lastName, phone).then(() => {
  //     this.loadEmailsBulk(i + 1);
  //   }).catch(() => {
  //     this.loadEmailsBulk(i + 1);
  //   });
  // }
}
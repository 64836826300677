import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NoContentComponent } from 'src/app/pages/404/no-content.component'
import { PostEventComponent } from 'src/app/pages/post-event/post-event-page.component';
import { TermsComponent } from 'src/app/pages/terms/terms.component';
import { PrivacyComponent } from 'src/app/pages/privacy/privacy.component';
import { PrivacyExtComponent } from 'src/app/pages/privacy-ext/privacy-ext.component';
import { FaqComponent } from 'src/app/pages/faq/faq.component';
import { CookieComponent } from 'src/app/pages/cookie/cookie.component';
import { AboutComponent } from 'src/app/pages/about/about.component';
import { PurchaseComponent } from 'src/app/pages/purchase/purchase.component';
//import { CartComponent } from './pages/cart/cart.component';
//import { ProductPageComponent } from 'src/app/pages/product-page/product-page.component';
//import { DesignersResolver } from 'src/app/resolvers/designes.resolve';

const routes: Routes = [
  {
    path: '',
    component: PostEventComponent
  },
  // {
  //   path: 'shows/:showDate/:productId',
  //   component: ProductPageComponent,
  //   resolve: { product: DetailProductResolver, designers: DesignersResolver }
  // },
  // {
  //   path: 'cart',
  //   component: CartComponent,
  //   resolve: { designers: DesignersResolver },
  // },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  },
  {
    path: 'privacy_extended',
    component: PrivacyExtComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'purchase',
    component: PurchaseComponent
  },
  {
    path: 'cookie',
    component: CookieComponent
  },
  {
    path: '404',
    component: NoContentComponent
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, ViewEncapsulation, HostListener, ElementRef, OnInit, Inject, ViewChild } from "@angular/core";
import { GlobalService } from "../../services/global.service";
import { ShopifyService } from "../../services/shopify.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { ParseService } from "../../services/parse.service";
import { CartService } from "../../services/cart.service";
import { CacheService } from "../../services/cache.service";
import { GoogleAnalyticsService } from "../../services/google-analytics.service";
import { filter } from "rxjs/operators";
import { environment } from "../../../environments/environment";
const MOBILE_MAX_WIDTH = 1200;

@Component({
  selector: 'app-new-detail-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductPageComponent implements OnInit {
  product: any;
  productId: any;
  collections: any;
  selectedCollection: any;
  routerEventsSubs;
  isSoldOut: boolean = false;
  isCheckoutInProgress: boolean = false;
  isFooterSelectBoxActive: boolean = false;
  showDatesSelector: boolean = false;
  stickyShowMoreOptions: boolean = false;
  showHintTooltip: boolean = false;
  environment = environment;
  checkoutButtonText: string = environment.default_checkout_text;
  confirmItemAdded = false;
  pageOptions: any = {
    quantity: 1,
    isValidOrder: false,
    isDateOptionSelected: false,
    price: 0,
    date: {
      formatted: {}
    },
    tickets: {
      selected: false,
      active: false
    }
  };

  @ViewChild('datesSelector') datesSelector: ElementRef;

  @HostListener("window:scroll", [])
  onWindowScroll() {
    this.checkSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    console.log(event.target.innerWidth);

    if (event.target.innerWidth < MOBILE_MAX_WIDTH) {
      this.showDatesSelector = true;
    } else {
      this.checkSize();
    }
  }

  @HostListener('document:click', ['$event'])
  documentClick(event) {
    let path = this.getParents(event.srcElement);
    let onDatesPickerClick = path.some((el) => el.id === 'dates-sticky');
    if (!onDatesPickerClick && this.stickyShowMoreOptions) {
      if (this.parseInt(this.pageOptions.quantity) > 5) this.pageOptions.quantity = 1;
      this.stickyShowMoreOptions = false;
    }
  }

  constructor(private shopifyService: ShopifyService,
    private route: ActivatedRoute,
    private parseService: ParseService,
    private cacheService: CacheService,
    private globalService: GlobalService,
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private cartService: CartService,
    private googleAnalyticsService: GoogleAnalyticsService) {
  }

  ngOnInit() {
    this.productId = this.route.params['value'].productId;
    this.cacheService.getProductById(this.productId).then(product => {
      this.product = this.parseService.toProduct(product);
    })

    this.globalService.setLoaded();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkSize();
    }, 500);
    this.googleAnalyticsService.sendOptimizeEvent('product_page_loaded');
  }

  ngOnDestroy() {
    if (this.routerEventsSubs) this.routerEventsSubs.unsubscribe();
  }

  ngOnChanges() {
    this.product = this.parseService.toProduct(
      this.route.snapshot.data
    );
  }

  track(category, action, label) {
    this.googleAnalyticsService.sendEvent('event', {
      'send_to': environment.ga_identifier,
      'event_category': category,
      'event_action': action,
      'event_label': label
    });
  }

  createCart(order) {
    if (!this.pageOptions.isValidOrder || !this.checkIfVariantAvailable(this.pageOptions.tickets.active)) {
      this.showHintTooltip = true;
      setTimeout(() => this.showHintTooltip = false, 4000);
      return;
    }
    let newCartProduct = Object.assign({}, order.product);
    newCartProduct.activeOption = this.getActiveOption(); //fallback to support old cart functionality TODO: rewrite it
    this.cartService.cart = { type: 'update', product: newCartProduct, quantity: this.parseInt(order.quantity) };
    this.track(`${environment.brandText}: Purchase Package`, 'Add to cart', newCartProduct.title);
    this.googleAnalyticsService.addToCart(newCartProduct, this.parseInt(order.quantity));
    this.showSuccessAddInfo();
  }

  setOptionAsActive(d) {
    this.pageOptions.date = d;
    this.pageOptions.isDateOptionSelected = true;
    this.track(`${environment.brandText}: Select Date`, 'Select date on package details page', d.formatted.soDate);
    this.isSoldOut = this.checkIfSoldOut();
    if (!this.checkIfVariantAvailable(this.pageOptions.tickets.active))
      this.pageOptions.tickets = { selected: false, active: false };
    this.countPrice();
  }

  checkIfDateAvailable(item) {
    return item.value.options.some(x => x.available && x.hasTicket == this.pageOptions.tickets.active);
  }

  checkIfVariantAvailable(val) {
    if (!this.pageOptions.date.options)
      return true;
    return this.pageOptions.date.options.some(x => x.available && x.hasTicket == val);
  }

  checkIfSoldOut() {
    if (!this.pageOptions.date.options)
      return true;
    return this.pageOptions.date.options.every(x => !x.available);
  }

  selectQny(qny) {
    this.pageOptions.quantity = qny;
    this.isFooterSelectBoxActive = false;
    this.countPrice();
  }

  countPrice() {
    if (this.pageOptions.isDateOptionSelected && this.pageOptions.tickets.selected) {
      this.pageOptions.price = this.getActiveOption().price * this.pageOptions.quantity;
      this.pageOptions.isValidOrder = true;
    } else
      this.pageOptions.isValidOrder = false;
  }

  openFooterQuantities() {
    this.isFooterSelectBoxActive = !this.isFooterSelectBoxActive;
  }

  parseInt(qny) {
    return parseInt(qny)
  }

  checkSize() {
    if (this.datesSelector && this.datesSelector.nativeElement.getClientRects()[0]) {
      if (this.showDatesSelector !== this.datesSelector.nativeElement.getClientRects()[0].bottom > window.innerHeight) {
        this.showDatesSelector = this.datesSelector.nativeElement.getClientRects()[0].bottom > window.innerHeight;
        if (!this.showDatesSelector) this.isFooterSelectBoxActive = false;
      }
    }
  }

  gotoDetailProductPage(product) {
    this.router.navigate(['/event', product.id]);
    this.track(`${environment.brandText}: Purchase Package`, 'Reserve from package details page', product.title);
  }

  gotoCart() {
    //this.router.navigate(['/cart']);
  }

  showStickyOptions() {
    this.stickyShowMoreOptions = true;
  }

  getParents(elem) {
    let parents = [];
    for (; elem && elem !== document; elem = elem.parentNode) {
      parents.push(elem);
    }
    return parents;
  }

  showSuccessAddInfo() {
    this.confirmItemAdded = true;
    this.checkoutButtonText = environment.added_checkout_text;
    setTimeout(() => {
      this.checkoutButtonText = environment.default_checkout_text;
    }, 500)
  }

  changeTicketOption(value) {
    this.pageOptions.tickets.selected = true;
    this.pageOptions.tickets.active = value;
    this.countPrice();
  }

  getActiveOption() {
    return this.pageOptions.date.options.filter((o) => {
      return o.hasTicket === this.pageOptions.tickets.active
    })[0];
  }

}

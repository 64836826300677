import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cookies-banner',
  templateUrl: './cookies-banner.component.html',
  styleUrls: ['./cookies-banner.component.scss']
})
export class CookiesBannerComponent {
  isBannerOpened: boolean = !localStorage.getItem('COOKIES_POLICY');
  constructor(private router: Router) { }

  ngOnDestroy(): void {
    this.isBannerOpened = false;
  }

  closeBanner() {
    this.isBannerOpened = false;
    localStorage.setItem('COOKIES_POLICY', 'agreed');
  }
}
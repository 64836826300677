// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  isSignUpMode: true,
  brand: 'AUGUSTA_AT_NIGHT',
  brandText: 'Augusta At Night',
  domain: 'endeavorexperiences.com',
  shopifyaccesstoken: '5bc9b8a8fa1403559f7ad448acf09144',
  rollbar_token: '63fb85836f014fef90b82c988af016d5',
  ga_identifier: 'UA-128565415-1',
  colection_identifier: 'AUGUSTA_AT_NIGHT____',
  first_collection_id: 'Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzQyNTAzNzAwNTQw',
  promoProductId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzM0MjU0NTQ3NTE4MDg=',
  formBundleProductId: '',
  amazon_lambda_token: '3Faywbjh5o3JZfn7M2wxe2G28U3rAZn27GdzndLb',
  amazon_lambda_gateway: 'https://v6gnxg6p7i.execute-api.us-west-2.amazonaws.com/default',

  title1: "DON'T MISS OUT ON THE NEXT",
  title2: 'NEW YORK FASHION WEEK SEPTEMBER 2019',

  annProject: true,
  miamiProject: false,
  nyfw: false,
  friese: false,

  firebase: {
    apiKey: "AIzaSyCvM68jrSPrtpdgF2meaBKRdiU5EPNaOrM",
    authDomain: "nyfw-28cd9.firebaseapp.com",
    databaseURL: "https://nyfw-28cd9.firebaseio.com",
    projectId: "nyfw-28cd9",
    storageBucket: "nyfw-28cd9.appspot.com",
    messagingSenderId: "404481823894",
    designers_collection: "prod/data"
  },

  videoUrls: null, //{
  //   videoDesktop: {
  //     url: '',
  //     poster: ''
  //   },
  //   videoMobile: {
  //     url: '',
  //     poster: ''
  //   }
  // },

  data: {
    phoneLink: 'tel:+1-844-325-0720',
    phone: '+1 (844) 325-0720',
    emailLink: 'mailto:experiences@endeavorco.com',
    email: 'experiences@endeavorco.com',
  },

  headerLinks: [],

  heroTitleHtml: ``,

  default_checkout_text: 'Add to cart',
  added_checkout_text: 'Added!',
  mainPhotoBackground: '',
  is_designers_announcement_enabled: true,
  showSignupFooter: false,
  showAppLayoutFooter: false,
  showSignupFooterSkin2: true,

  showHeroBundle: false,
  heroPosterImg: '/assets/images/ufc/poster.jpg',
  showCartBadge: false,
  consiergeEmailSubject: 'Frieze VIP Experience Request',
  consiergeEmailText: '',
  showSignUpHome: false,
  hurryUpPopupBrand: 'AUGUSTA AT NIGHT VIP',
  topHeaderLogoLink: '/',
  bottomHeaderLogoLink: '/',
  showStartingAtLabel: true,
  sortRelatedProductsDesc: true,

  topHeaderLogo: {
    themplate: ``,
    link: '/',
    target: ''
  },
  bottomHeaderLogo: {
    themplate: ``,
    link: '/',
    target: ''
  },
  bundleExtendedForm: false,
  masonry: {
    masonryHintText: 'Taylor Hill/Getty Images for Augusta at Night',
    masonryTitleThemplate: `<p class="masonry-title-text">
                              <span class="masonry-title-text__part">Highlights from Augusta at Night 2019: </span>
                              Hootie & The Blowfish featuring Sheryl Crow
                            </p>`,
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
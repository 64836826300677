import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { GlobalService } from "../../services/global.service";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { GoogleAnalyticsService } from "../../services/google-analytics.service";
import { SwiperDirective } from "ngx-swiper-wrapper";
import { environment } from '../../../environments/environment';
import { CacheService } from "src/app/services/cache.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;

  environment = environment;
  allProducts: any = [];
  promoProduct: any;
  relatedProducts: any;

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: any,
    private globalService: GlobalService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private cacheService: CacheService,
  ) {
  }

  ngOnInit() {
    this.cacheService.currentProducts.subscribe(data => {
      this.allProducts = data;

      let promoProduct = this.allProducts.filter(x => x.isPromoted);
      this.promoProduct = promoProduct && promoProduct.length > 0 ? promoProduct[0] : null;
      this.relatedProducts = this.allProducts;
    })

    this.globalService.setLoaded();
    this.googleAnalyticsService.sendOptimizeEvent('landingpage_loaded');
  }

  track(category, action, label) {
    this.googleAnalyticsService.sendEvent('event', {
      'send_to': environment.ga_identifier,
      'event_category': category,
      'event_action': action,
      'event_label': label
    });
  }

  gotoPage(page) {
    this.router.navigate([page]);
  }

  gotoDetailProductPage(product) {
    this.router.navigate(['/event', product.id]);
    this.track(`${environment.brandText}: Purchase Package`, 'Reserve from home page', product.title);
  }

}


import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader-centered',
  templateUrl: './loader-centered.component.html',
  styleUrls: ['./loader-centered.component.scss']
})
export class LoaderCenteredComponent implements OnInit {

  constructor() { }

  ngOnInit() {}
}
